import React from 'react';

import Icon, { type IconProps } from '@atlaskit/icon';
import type { CustomGlyphProps } from '@atlaskit/icon/types';

const GemGlyph = (props: CustomGlyphProps) => (
	<svg
		{...props}
		width="16"
		height="16"
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M2.61401 1.3525C2.75106 1.13321 2.99141 1 3.25001 1H12.75C13.0086 1 13.249 1.13321 13.386 1.3525L15.886 5.3525C16.0594 5.62995 16.033 5.98778 15.8206 6.23671L8.57063 14.7367C8.42813 14.9038 8.21958 15 8 15C7.78043 15 7.57188 14.9038 7.42938 14.7367L0.17938 6.23671C-0.0329407 5.98778 -0.059397 5.62995 0.114006 5.3525L2.61401 1.3525ZM3.66569 2.5L2.10319 5H4.69861L5.47986 2.5H3.66569ZM7.0514 2.5L6.27015 5H9.72986L8.94861 2.5H7.0514ZM10.5201 2.5L11.3014 5H13.8968L12.3343 2.5H10.5201ZM13.6245 6.5H11.2956L9.87241 10.899L13.6245 6.5ZM8 11.8135L9.71908 6.5H6.28093L8 11.8135ZM4.70438 6.5L6.1276 10.899L2.37547 6.5H4.70438Z"
			fill="currentColor"
		/>
	</svg>
);

export const GemIcon = (props: IconProps) => <Icon glyph={GemGlyph} {...props} />;
